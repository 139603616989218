.gpt3__navbar{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}
.gpt3__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo img {
    width: 52px;
    height: 52px;
}
.gpt3__navbar-links_logo{
    margin-right: 4rem;
}

.gpt3__navbar-links_container {
    display: flex;
    
    flex-direction: row;
   
}

.gpt3__navbar-links_container p,
.gpt3__navbar-menu_container p{
    color: #152118;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;

    text-transform: capitalize;
    margin: 0 1.15rem;
    cursor: pointer;
    outline: none;
    border: 0;

}